.navbar {
    width: 100%;
    height: 40px;
    background-color: var(--yellow);
    color: var(--red);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.navbarTight {
    display: none;
}

.center {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.up {
    height: 40px;
    display: flex;
    flex-direction: row;
}

.nav-menu {
    flex: 2;
    padding-inline-start: 0px;
    display: flex;
    flex-direction: row;
    list-style: none;
    justify-content: center;
}

.nav-item {
    margin: 0px 3px 0px 3px;
    display: flex;
}

.nav-links {
    text-decoration: none;
    color: var(--red);
    text-align: center;
}

.topLeft {
    flex: 1;
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.topRight {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 10px;
}

@media (max-width: 750px) {

    .navbar {
        flex-direction: column;
        height: auto;
        
    }

    .down {
        display: grid;
    }

    .navbarWide {
        display: none;
    }

    .navbarTight {
        display: flex;
    }

    .nav-menu {
        flex-direction: column;
    }

    .nav-item {
        align-items: center;
        justify-content: center;
    }
}