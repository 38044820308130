.Joke {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.JokeTitle {
    width: 90%;
    text-align: center;
    color: var(--yellow);
    font-size: 3vw;
}

.JokeImg {
    width: 60%;
}

@media (max-width: 825px) {

    .JokeTitle {
        width: 90%;
        font-size: 6vw;
    }

    .JokeImg {
        width: 90%;
    }

}