.Collapsible {
    width: 100%;
}

.CollapsibleHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 5vw;
}

.CollapsibleIcon {
    width: 12%;
    color: white;
}

.CollapsibleTitle {
    white-space: nowrap;
    margin: 0% 2%;
}

.CollapsibleBar {
    width: 100%;
    height: 0.5vw;
    background-color: var(--red);
}

.CollapsibleBody {
    height: 0px;
    overflow: hidden;
    transition: height 0.5s ease-in-out;
}