.Landing {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.BodyContainer {
  width: 90%;
  font-size: 2vw;
}

b {
  color: var(--yellow);
}

.BodyContainer a {
  color: var(--yellow);
}

@media (max-width: 825px) {
  .BodyContainer {
    font-size: 4vw;
  }
}