.HeaderContainer {
    width: 90%;
    height: 30vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 40px 0px 40px 0px;
}

.profileImg {
    width: 25vw;
    height: 25vw;
}

.HeaderTextContainer {
    height: 30vw;
    width: 62vw;
    font-size: 5.2vw;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
}

.HatsContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 3.5vw;
}

.SchoolContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 3.3vw;
}

.School {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-size: 2.2vw;
}

.StudiesContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 1.6vw;
}

.HeaderBottom {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    font-weight: bold;
    font-size: 2.4vw;
}

.CompaniesContainer {
    height: 100%;
    min-width: 35vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-weight: normal;
}

.CompanyLogos {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.CompanyLogo {
    height: 4vw;
}

@media (max-width: 825px) {
    .Landing {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .HeaderContainer {
        width: 100%;
        margin: 10px 0px 10px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: auto;
    }

    .profileImg {
        width: 60vw;
        height: 60vw;
    }

    .HeaderTextContainer {
        width: 90%;
        height: auto;
        font-size: 8.2vw;
    }


    .HatsContainer {
        font-size: 5.6vw;
    }

    .SchoolContainer {
        font-size: 5.6vw;
    }

    .School {
        width: auto;
        font-size: 2.8vw;
    }

    .StudiesContainer {
        font-size: 2.4vw;
    }

    .HeaderBottom {
        font-size: 4vw;
    }

    .CompanyLogo {
        height: 5.2vw;
    }
}