.Footer {
    width: 90%;
    height: 40px;
    padding: 0% 5% 0% 5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--red);
    color: var(--yellow);
}

.FooterCopyright {
    height: 100%;
    flex: 2;
    display: flex;
    align-items: center;
    font-size: 1.5vw;
}

.FooterEmail {
    height: 100%;
    flex: 2;
    display: flex;
    align-items: center;
    font-size: 1.5vw;
}

.FooterEmailText {
    margin-left: 5px;
}

.FooterSocialMedia {
    width: 20%;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.FooterSocialIconLink {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.FooterSocialIcon {
    height: 22px;
    color: var(--yellow);
    cursor: pointer;

}

@media (max-width: 825px) {

    .FooterCopyright {
        font-size: 2vw;
    }

    .FooterEmail {
        font-size: 2vw;
    }
    .FooterSocialIcon {
        height: 16px;
    
    }
    
}